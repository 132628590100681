import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import MetisMenu from 'metismenujs';
import {NavigationEnd, Router} from '@angular/router';

import {HttpClient} from '@angular/common/http';

import {SideBarService} from '../../../network/services/sidebar.service';
import {GenericListResponse} from '../../../models/generic-list-response';
import {AccessControlCategory} from '../../../models/access-control.model';
import {KeywordsAndConstants} from '../../../core/keywords-and-constants';
import {BaseComponent} from '../../base/base/base.component';
import {LocalStorageService} from '../../../network/services/local-storage.service';
import {TokenCallbackService} from '../../../network/services/token-callback.service';
import {NotificationService} from "../../../network/services/notification.service";
import {NotificationModalComponent} from "../../pages/common/notification-modal/notification-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})

/**
 * Sidebar component
 */
export class SidebarComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('componentRef') scrollRef: any;
  @Input() isCondensed = false;
  @ViewChild('sideMenu') sideMenu!: ElementRef;
  menu: any;
  sideBarMenuItem: any = [];

  accessControlData: any;
  accessControlList = new Array<any>();
  notificationCountsByGroup:any;
  bsModalRef?: BsModalRef;

  constructor(
    private router: Router,
    constants: KeywordsAndConstants,
    _service: LocalStorageService,
    _callBackService: TokenCallbackService,
    http: HttpClient,
    private _sideBarService: SideBarService,
    private _notificationService: NotificationService,
    private ref: ChangeDetectorRef,
    private modalService:BsModalService
  ) {
    super(http, constants, _service, _callBackService, true);
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });
  }

  ngOnInit() {
    let sideBarCallSubscription = this._callBackService
      .observeForCallingApisInSideBar()
      .subscribe((readyToCallSideBarApis: boolean) => {
        if (readyToCallSideBarApis) {
          this.getUnreadNotificationCounts();
          this.callSideBarList();
          sideBarCallSubscription.unsubscribe();
        }
      });
    this.initialize();
    this._scrollElement();
    this._sideBarService.callbackResponse.subscribe((response) => {
      this.initialize();
      this.ref.detectChanges();
      this._scrollElement();
      if (this.sideMenu !== undefined) {
        this.sideMenu.nativeElement.focus();
      }
      this.menu = new MetisMenu(this.sideMenu.nativeElement);
      this._activateMenuDropdown();
    });
    this._notificationService.callbackResponseForNotificationCount.subscribe((res:any)=>{
      this.notificationCountsByGroup=res.groups;
      this.constants.byCategoryValue.forEach((e: any) => {
        this.accessControl(e);
      });
      localStorage.setItem(
        this.constants.byCategory,
        JSON.stringify(this.constants.byCategoryValue)
      );
      this._sideBarService.updateLocalStorage(this.constants.byCategoryValue);
    })
  }

  callSideBarList() {
    let intervalIdForInitialApiCalls = setInterval(() => {
      if (!this.pageApisCalled) {
        this.pageApisCalled = true;
        this.getSideBarList();
        this.getAllAccessControl();
        clearInterval(intervalIdForInitialApiCalls);
      }
    }, 10);
  }

  getSideBarList() {
    this._sideBarService
      .getbyCategory()
      .subscribe((res: GenericListResponse<AccessControlCategory>) => {
        const filteredAccessControlCategories = res.data.filter((item: any) => {
          return item.shouldShow;
        });
        this.constants.byCategoryValue = filteredAccessControlCategories;
        this.constants.byCategoryValue.sort((a: any, b: any) => {
          return a.indexOrder - b.indexOrder;
        });
        this.constants.byCategoryValue.forEach((e: any) => {
          this.accessControl(e);
        });
        localStorage.setItem(
          this.constants.byCategory,
          JSON.stringify(this.constants.byCategoryValue)
        );
        this.initialize();
        this.ref.detectChanges();
        this._scrollElement();
        if (this.sideMenu !== undefined) {
          this.sideMenu.nativeElement.focus();
        }
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
        this._activateMenuDropdown();
      });
  }

  accessControl(e: any) {
    var pageUrlFromAccessControlsList = '';
    e.accessControls.forEach((val: any) => {
      var method = val.method.substring(0, val.method.lastIndexOf('/') + 1);
      val.method = method.slice(0, -1);
      pageUrlFromAccessControlsList = val.method;
    });
    e.unreadCount = '';
    this.notificationCountsByGroup?.forEach((val: any) => {
      if (e.identifier == val.accessControlCategoryIdentifier) {
        e['unreadCount'] = val.count;
      }
    });
    if (pageUrlFromAccessControlsList != '') {
      pageUrlFromAccessControlsList = pageUrlFromAccessControlsList.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
      )
      e.pageUrl = pageUrlFromAccessControlsList;
    }
    else{
      e.pageUrl = e.pageUrl.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($:any, ofs:any) => (ofs ? "-" : "") + $.toLowerCase()
      )
    }
    if (e.subCategories.length > 0) {
      e.subCategories = e.subCategories.filter(
        (item: any) => item.shouldShow === true
      );
      e.subCategories.sort((a: any, b: any) => {
        return a.indexOrder - b.indexOrder;
      });
      e.subCategories.forEach((subCategoriesval: any) => {
        this.accessControl(subCategoriesval);
      });
    }
  }

  getAllAccessControl() {
    this._sideBarService.getAccessControl().subscribe((res: any) => {
      this.accessControlData = res.data;
      for (let i = 0; this.accessControlData.length > i; i++) {
        this.accessControlList.push(this.accessControlData[i].name);
      }
      localStorage.setItem(
        this.constants.accessControlItem,
        JSON.stringify(this.accessControlList)
      );
    });
  }

  ngAfterViewInit() {
    if (this.sideMenu !== undefined) {
      this.sideMenu.nativeElement.focus();
    }
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }

  getUnreadNotificationCounts(){
    this._notificationService.getUnreadNotificationCountsByACGroup().subscribe(res=>{
      this.notificationCountsByGroup=res.groups;
      this._notificationService.updateNotificationCount(res);
    })
  }

  openNotificationModal(val:any){
    if(val.unreadCount){
      this.bsModalRef= this.modalService.show(NotificationModalComponent,{
        class: 'right-side-modal-class',
      })
      this.bsModalRef.content.identifier = val.identifier;
    }
  }

  toggleMenu(event: any) {
    event.currentTarget.nextElementSibling.classList.toggle('mm-show');
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName('mm-active').length > 0) {
        const currentPosition: any =
          document.getElementsByClassName('mm-active')[0];
        if (currentPosition['offsetTop'] > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition['offsetTop'] + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className: any) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links: any = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) {
              childAnchor.classList.add('mm-active');
            }
            if (childDropdown) {
              childDropdown.classList.add('mm-active');
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') {
                  childanchor.classList.add('mm-active');
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    let menuItem: any = localStorage.getItem(this.constants.byCategory);
    this.sideBarMenuItem = JSON.parse(menuItem);
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: any) {
    return item.subCategories !== undefined
      ? item.subCategories.length > 0
      : false;
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.accessControlList = [];
    this.constants.byCategoryValue = [];
    this.accessControlData = '';
  }
}

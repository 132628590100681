import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PageTitleComponent } from "./page-title/page-title.component";
import { ShortNamePipe } from "../pages/short-name.pipe";
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [PageTitleComponent, ShortNamePipe, LoaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
  ],
  exports: [PageTitleComponent, ShortNamePipe]
})
export class UIModule { }

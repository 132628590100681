import { Component } from '@angular/core';
import { LoaderComponent } from './ui/loader/loader.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Atlpay';
  public awesomeComponent = LoaderComponent;
}

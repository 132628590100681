import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DialogUtil } from '../../../utils/dialog-utils';
import { ModalArguments } from '../../../models/modal-arguments';
import { ModalButtonType } from '../../../enums/modal_button_type';
import { LocalStorageService } from '../../../network/services/local-storage.service';
import { KeywordsAndConstants } from '../../../core/keywords-and-constants';
import { AdminLoginService } from 'src/network/services/admin/admin-login.service';
import {NotificationModalComponent} from "../../pages/common/notification-modal/notification-modal.component";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {NotificationService} from "../../../network/services/notification.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  @Output() mobileMenuButtonClicked = new EventEmitter();
  userInfo: any;
  response: any;
  bsModalRef?: BsModalRef;
  totalCount:any;
  constructor(
    public dialog: DialogUtil,
    public constants: KeywordsAndConstants,
    public _localStorageService: LocalStorageService,
    private _adminLoginService: AdminLoginService,
    private _notificationService: NotificationService,
    private modalService:BsModalService
  ) {}

  ngOnInit() {
    this._adminLoginService.callbackResponseForUser.subscribe((response) => {
      this.response = response;
      let userDetails: any = localStorage.getItem(
        this.constants.LocalStorageUserInfo
      );
      this.userInfo = JSON.parse(userDetails);
    });
    if (!this.response) {
      let userDetails: any = localStorage.getItem(
        this.constants.LocalStorageUserInfo
      );
      this.userInfo = JSON.parse(userDetails);
    }
    this._notificationService.callbackResponseForNotificationCount.subscribe((res:any)=>{
      this.totalCount=res.total;
    })
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }
  logout() {
    const contextAlias = this;
    this.dialog.errorShowModal(
      new ModalArguments('Are you sure you want to logout?')
        .withButtonType(ModalButtonType.DOUBLE_BUTTON)
        .withButtonPositiveText('Logout')
        .withButtonNegativeText('Cancel')
        .withCallback({
          buttonNegativeClicked() {},
          buttonPositiveClicked() {
            contextAlias._localStorageService.logout();
          },
          buttonSingleClicked() {},
        })
    );
  }
  openNotificationModal(){
    this.bsModalRef= this.modalService.show(NotificationModalComponent,{
     class: 'right-side-modal-class',
     })
  }
}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { KeywordsAndConstants } from 'src/core/keywords-and-constants';
import { LocalStorageService } from './services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  currentRoute!: string;
  needToChangePassword: boolean = false;

  constructor(
    private constants: KeywordsAndConstants,
    private router: Router,
    private _localStorageService: LocalStorageService
  ) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = localStorage.getItem(this.constants.LocalStorageToken);
    const url = route.routeConfig?.path;
    if (token) {
      return !this.needToChangePassword;
    } else {
      if (
        url == 'login' ||
        url == 'password/forgot-password' ||
        url == 'otp-verification'
      ) {
        return true;
      }
    }
    this._localStorageService.logout();
    return false;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Apis } from 'src/core/apis';
import { ProfileResponseModel } from 'src/models/profile.model';
import { LoginRequest } from 'src/models/reqres/admin-login-request';
import { TokenAndRefreshToken } from 'src/models/tokenandrefreshtoken.model';

const API_SignUp_URL = Apis.merchantSignUp;
const API_LOGIN_URL = Apis.merchantLogin;
const API_USER_ME_URL = Apis.merchantMe;
const API_AddAddress_Field_Schema_Values_URL =
  Apis.addAddressFieldSchemaValuesForMerchant;
const API_addBusinessIdentifierFieldSchemaValues_URL =
  Apis.addBusinessIdentifierFieldSchemaValues;
const API_updateOperatingCurrency_URL = Apis.updateOperatingCurrency;
const API_UPDATE_CURRENCY_PRIORITY_URL = Apis.updateCurrencyPriority;
const API_updateDevContact_URL = Apis.updateDevContact;
const API_updateBusinessContact_URL = Apis.updateBusinessContact;
const API_updateTaxInfo_URL = Apis.updateTaxInfo;
const API_updateStakeHolder_URL = Apis.updateStakeHolder;
const API_FOR_UPLOAD_DOCUMENTS_BY_URL_URL = Apis.documentUploadByUrl;
const API_FOR_ATTACH_DOCUMENT_TO_MERCHANT_PROFILE_URL =
  Apis.attachDocumentToMerchant;
const API_attachDocumentToStakeHolder_URL = Apis.attachDocumentToStakeHolder;
const API_ADD_SETTLEMENT_ACCOUNT_URL = Apis.addSettlementAccount;
const API_ADD_WALLET_SETTLEMENT_CONFIG_URL = Apis.addWalletSettlement;
const API_SETTLEMENT_ACCOUNT_REACTIVATION_DURATION_URL =
  Apis.merchantSettlementReactivationDuration;

const API_UPDATE_COLOR_CODE_HEX_URL = Apis.updateColorCodeHex;
const API_ATTACH_LOGO_DOCUMENT_TO_MERCHANT_URL = Apis.updateLogo;

@Injectable({
  providedIn: 'root',
})
export class MerchantSignupService implements OnDestroy {
  constructor(private http: HttpClient) {}

  signUp(
    data: any,
    currencyPriorityList: any
  ): Observable<TokenAndRefreshToken> {
    return this.http.post<TokenAndRefreshToken>(API_SignUp_URL, {
      name: data.name,
      emailId: data.emailId,
      mobileNumber: data.mobileNumber,
      countryId: data.countryId,
      countryIdForMobileNumber: data.countryIdForMobileNumber,
      currencyDataRequests: currencyPriorityList,
      password: data.password,
      clientDevicePlatform: data.clientDevicePlatform,
      deviceUserAgent: data.deviceUserAgent,
      uniqueIdentifierId: data.uniqueIdentifierId,
      fcmId: data.fcmId,
    });
  }
  login(data: LoginRequest): Observable<TokenAndRefreshToken> {
    return this.http.post<TokenAndRefreshToken>(API_LOGIN_URL, data);
  }
  user(): Observable<ProfileResponseModel> {
    return this.http.get<any>(API_USER_ME_URL);
  }
  addAddressFieldSchemaValues(data: any): Observable<any> {
    return this.http.post<any>(API_AddAddress_Field_Schema_Values_URL, {
      fieldSchemaValueRequest: data,
    });
  }
  addBusinessIdentifierFieldSchemaValues(data: any): Observable<any> {
    return this.http.post<any>(API_addBusinessIdentifierFieldSchemaValues_URL, {
      fieldSchemaValueRequest: data,
    });
  }
  updateOperatingCurrency(data: any): Observable<any> {
    return this.http.put<any>(API_updateOperatingCurrency_URL, {
      currencyDataRequestList: data,
    });
  }
  updateCurrencyPriority(id: any, priority: any): Observable<any> {
    return this.http.put<any>(API_UPDATE_CURRENCY_PRIORITY_URL, {
      operatingCurrencyId: id,
      priority: priority,
    });
  }
  updateDevContact(data: any): Observable<any> {
    return this.http.put<any>(API_updateDevContact_URL, {
      emailId: data.emailId,
      mobileNumber: data.mobileNumber,
      countryIsdCode: data.countryId,
    });
  }
  updateBusinessContact(data: any): Observable<any> {
    return this.http.put<any>(API_updateBusinessContact_URL, {
      emailId: data.bussinessEmailId,
      mobileNumber: data.bussinessMobileNumber,
      countryIsdCode: data.bussinessCountryId,
    });
  }
  updateStakeHolder(data: any): Observable<any> {
    return this.http.put<any>(API_updateStakeHolder_URL, {
      stakeHolderData: data,
    });
  }
  updateTaxInfo(data: any): Observable<any> {
    return this.http.put<any>(API_updateTaxInfo_URL, {
      taxInfoData: data,
    });
  }
  uploadDocument(fileName: any, userId: any, fileType: any): Observable<any> {
    // const formData: FormData = new FormData();
    // formData.append('fileName', file);
    // formData.append('uploadedFor', userId);
    // formData.append('awsS3FileType', fileType);
    let formOp = {
      fileName: fileName,
      uploadedFor: userId,
      awsS3FileType: fileType,
    };
    let baseURL = `${API_FOR_UPLOAD_DOCUMENTS_BY_URL_URL}`;
    if (Object.keys(formOp).length > 0) {
      let urlParameters = Object.entries(formOp)
        .map((e) => e.join('='))
        .join('&');
      baseURL = `${baseURL}?${urlParameters}`;
    }
    return this.http.get<any>(`${baseURL}`);
  }

  uploadFilesByUrl(file: any, url: any): Observable<any> {
    return this.http.put<any>(`${url}`, file);
  }

  attachDocumentToMerchant(
    merchantId: any,
    documentCategoryId: any,
    documentId: any
  ): Observable<any> {
    return this.http.post<any>(
      API_FOR_ATTACH_DOCUMENT_TO_MERCHANT_PROFILE_URL,
      {
        merchantId: merchantId,
        documentCategoryId: documentCategoryId,
        documentId: documentId,
      }
    );
  }
  attachDocumentToStakeHolder(
    merchantId: any,
    stakeHolderId: any,
    documentCategoryId: any,
    documentId: any
  ): Observable<any> {
    return this.http.post<any>(API_attachDocumentToStakeHolder_URL, {
      merchantId: merchantId,
      stakeHolderId: stakeHolderId,
      documentCategoryId: documentCategoryId,
      documentId: documentId,
    });
  }
  addSettlementAccount(data: any): Observable<any> {
    return this.http.post<any>(API_ADD_SETTLEMENT_ACCOUNT_URL, data);
  }

  addWalletSettlementConfig(data: any): Observable<any> {
    return this.http.post<any>(API_ADD_WALLET_SETTLEMENT_CONFIG_URL, data);
  }

  getSettlementAccountConfigReactivationDuration(): Observable<any> {
    return this.http.get<any>(API_SETTLEMENT_ACCOUNT_REACTIVATION_DURATION_URL);
  }

  updateColorCodeHex(data: any): Observable<any> {
    return this.http.put<any>(API_UPDATE_COLOR_CODE_HEX_URL, data);
  }
  attachLogoDocumentToMerchant(
    merchantId: any,
    documentId: any
  ): Observable<any> {
    return this.http.post<any>(API_ATTACH_LOGO_DOCUMENT_TO_MERCHANT_URL, {
      merchantId: merchantId,
      documentId: documentId,
    });
  }
  ngOnDestroy() {}
}

import {Component, HostListener, OnInit} from '@angular/core';
import {KeywordsAndConstants} from "../../../core/keywords-and-constants";
import {Router} from "@angular/router";
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

  constructor(   private router: Router,
                 public constants: KeywordsAndConstants, ) {
    if (localStorage.getItem(this.constants.LocalStorageToken)) {
      this.router.navigateByUrl('/dashboard',);
    }
  }

  ngOnInit() {
    this.loadJsFile("assets/js/main.js");
  }
  scrollToTop(){
    document.documentElement.scrollTop=0;
  }
  public loadJsFile(url: any) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('header')[0].appendChild(node);
  }
}

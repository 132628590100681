import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutsComponent } from './layouts.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VerticalComponent } from './vertical/vertical.component';
import { RecursiveMenuItemComponent } from './sidebar/recursive-menu-item.component';
import {UIModule} from "../ui/ui.module";
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    LayoutsComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    VerticalComponent,
    RecursiveMenuItemComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    SimplebarAngularModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule,
    UIModule
  ],
  providers: [BsDropdownConfig],
})
export class LayoutsModule {}

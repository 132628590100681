import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeywordsAndConstants } from 'src/core/keywords-and-constants';
import { AdminLoginService } from 'src/network/services/admin/admin-login.service';
import { LocalStorageService } from 'src/network/services/local-storage.service';
import { SideBarService } from 'src/network/services/sidebar.service';
import { DialogUtil } from 'src/utils/dialog-utils';
import { LoginRequest } from 'src/models/reqres/admin-login-request';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ErrorAction } from 'src/enums/error-action';
import { MessageType } from 'src/enums/message-type';
import { AccessControlCategory } from 'src/models/access-control.model';
import { GenericListResponse } from 'src/models/generic-list-response';
import { MerchantSignupService } from 'src/network/services/merchant/merchant-signup.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted: any = false;
  error: any = '';
  returnUrl!: string;
  password: any;
  show = false;
  uuid: any;


  constructor(private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private constants: KeywordsAndConstants,
    private _localStorageService: LocalStorageService,
    public _loginService: AdminLoginService,
    public _sideBarService: SideBarService,
    private dialog: DialogUtil,
    public _merchantService: MerchantSignupService,
  ) {
    this._localStorageService.deviceInformation();
    if (
      localStorage.getItem(this.constants.LocalStorageToken) &&
      localStorage.getItem(this.constants.isLoginType)
    ) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.password = 'password';
  }

  loginForm = this.formBuilder.group({
    emailId: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  get f() {
    return this.loginForm.controls;
  }

  onShowHideIcon() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }


  onSubmit() {
    this.submitted = true;
    this.uuid = localStorage.getItem('uniqueIdentifierId');

    Object.assign(this.loginForm.value, {
      clientDevicePlatform: this._localStorageService.platform,
      deviceUserAgent: this._localStorageService.deviceUserAgent,
      uniqueIdentifierId: this.uuid,
    });

    const newLoginValue = new LoginRequest();
    newLoginValue.set(this.loginForm.value);
    if (this.loginForm.invalid) {
      return;
    } else {
      this._merchantService.login(newLoginValue).subscribe(
        (res) => {
          localStorage.setItem(
            this.constants.LocalStorageToken,
            JSON.stringify(res.token)
          );
          localStorage.setItem(
            this.constants.LocalStorageRefreshToken,
            JSON.stringify(res.refreshToken)
          );
          this._localStorageService.backUpToken = JSON.stringify(res.token);
          this._localStorageService.backUpRefreshToken = JSON.stringify(
            res.refreshToken
          );

          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(res.token);

          localStorage.setItem(this.constants.isLoginType, decodedToken.type);
          this._merchantService.user().subscribe((user: any) => {
            localStorage.setItem(
              this.constants.LocalStorageUserInfo,
              JSON.stringify(user)
            );
            this.getSideBarList();
          });
        },
        (error) => {
          if (error.errorAction != ErrorAction.HANDLED) {
            this.dialog.showToast(error.message, MessageType.ERROR);
          }
        }
      );
    }
  }
  getSideBarList() {
    this._sideBarService
      .getbyCategory()
      .subscribe((res: GenericListResponse<AccessControlCategory>) => {
        const filteredAccessControlCategories = res.data.filter((item: any) => {
          return item.shouldShow;
        });
        this.constants.byCategoryValue = filteredAccessControlCategories;
        this.constants.byCategoryValue.sort((a: any, b: any) => {
          return a.indexOrder - b.indexOrder;
        });
        this.constants.byCategoryValue.forEach((e: any) => {
          this.accessControl(e);
        });
        localStorage.setItem(
          this.constants.byCategory,
          JSON.stringify(this.constants.byCategoryValue)
        );
        this.router.navigate(['/dashboard']);
      });
  }

  accessControl(e: any) {
    var pageUrlFromAccessControlsList = '';
    e.accessControls.forEach((val: any) => {
      var method = val.method.substring(0, val.method.lastIndexOf('/') + 1);
      val.method = method.slice(0, -1);
      pageUrlFromAccessControlsList = val.method;
    });
    e.unreadCount = '';
    if (pageUrlFromAccessControlsList != '') {
      pageUrlFromAccessControlsList = pageUrlFromAccessControlsList.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
      )
      e.pageUrl = pageUrlFromAccessControlsList;
    }
    else{
      e.pageUrl = e.pageUrl.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($:any, ofs:any) => (ofs ? "-" : "") + $.toLowerCase()
      )
    }
    if (e.subCategories.length > 0) {
      e.subCategories = e.subCategories.filter(
        (item: any) => item.shouldShow === true
      );
      e.subCategories.sort((a: any, b: any) => {
        return a.indexOrder - b.indexOrder;
      });
      e.subCategories.forEach((subCategoriesval: any) => {
        this.accessControl(subCategoriesval);
      });
    }
  }

}

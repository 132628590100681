export class LoginRequest {
    emailId: any;
    password: any;
    clientDevicePlatform: any;
    deviceUserAgent: any;
    uniqueIdentifierId: any;
  
    set(_data: LoginRequest) {
      const data = _data as LoginRequest;
      this.emailId = data.emailId;
      this.password = data.password;
      this.clientDevicePlatform = data.clientDevicePlatform;
      this.deviceUserAgent = data.deviceUserAgent
      this.uniqueIdentifierId = data.uniqueIdentifierId
    }
  }
  
import { ModalButtonType } from "src/enums/modal_button_type";
import { ModalButtonClickCallbackContract } from "src/utils/dialog-utils";
export class ModalArguments {
  public title: string = "";
  public buttonType: ModalButtonType = ModalButtonType.SINGLE_BUTTON;
  public buttonNegativeText: string = "No";
  public buttonPositiveText: string = "Yes";
  public buttonSingleText: string = "Okay";
  public dismissButtonNegativeOnClick: boolean = true;
  public dismissButtonPositiveOnClick: boolean = true;
  public dismissButtonSingleOnClick: boolean = true;
  public cancellable: boolean = true;
  public callback: ModalButtonClickCallbackContract | undefined;

  constructor(public message: string) {}

  withTitle(title: string): ModalArguments {
    this.title = title;
    return this
  }

  withButtonType(buttonType: ModalButtonType): ModalArguments {
    this.buttonType = buttonType;
    return this
  }

  withButtonNegativeText(buttonNegativeText: string): ModalArguments {
    this.buttonNegativeText = buttonNegativeText;
    return this
  }

  withButtonPositiveText(buttonPositiveText: string): ModalArguments {
    this.buttonPositiveText = buttonPositiveText;
    return this
  }

  withButtonSingleText(buttonSingleText: string): ModalArguments {
    this.buttonSingleText = buttonSingleText;
    return this
  }

  withCancellable(cancellable: boolean): ModalArguments {
    this.cancellable = cancellable;
    return this
  }

  withCallback(callback: ModalButtonClickCallbackContract): ModalArguments {
    this.callback = callback;
    return this
  }

  dontDismissPositiveButtonOnClick(): ModalArguments {
    this.dismissButtonPositiveOnClick = false;
    return this
  }

  dontDismissNegativeButtonOnClick(): ModalArguments {
    this.dismissButtonNegativeOnClick = false;
    return this
  }
}

import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AccessControlService} from "../../../../network/services/access-control.service";
import {NotificationService} from "../../../../network/services/notification.service";
import {StringVariable} from "../../../../core/string-variable";
import {DialogUtil} from "../../../../utils/dialog-utils";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {FormBuilder} from "@angular/forms";
import {KeywordsAndConstants} from "../../../../core/keywords-and-constants";
import {HttpClient} from "@angular/common/http";
import {LocalStorageService} from "../../../../network/services/local-storage.service";
import {TokenCallbackService} from "../../../../network/services/token-callback.service";
import {BaseComponent} from "../../../base/base/base.component";
import {ErrorAction} from "../../../../enums/error-action";
import {MessageType} from "../../../../enums/message-type";

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent extends BaseComponent
  implements OnInit, OnDestroy {
  id?: string;
  identifier?: string;
  notificationList:Array<any>=[];
  notificationListForSpecific:Array<any>=[];
  constructor(
    private route: ActivatedRoute,
    public _controlService: AccessControlService,
    public _notificationService: NotificationService,
    public variable: StringVariable,
    private dialog: DialogUtil,
    private modalService: BsModalService,
    private _elRef: ElementRef,
    public bsModalRef: BsModalRef,
    private router:Router,
    constants: KeywordsAndConstants,
    http: HttpClient,
    _service: LocalStorageService,
    _callBackService: TokenCallbackService
  ) {
    super(http, constants, _service, _callBackService);
  }

  ngOnInit() {
    let intervalIdForInitialApiCalls = setInterval(() => {
      if (!this.pageApisCalled && this.canCallPageApis()) {
        this.pageApisCalled = true;
        this.notificationListForSpecific = [];
        if(this.id){
          this.getNotificationById(this.id);
        }
        else this.getNotification();

        this.bsModalRef.onHide?.subscribe(reason => {
          if(this.identifier) this.allNotificationRead(this.identifier);
          else if(this.id) this.getUnreadNotificationCounts();
        })
        clearInterval(intervalIdForInitialApiCalls);
      }
    }, 10);
  }
  getNotification(){
    this._notificationService.getNotificationList('UNREAD').subscribe(res=>{
      this.notificationList=[];
      if(this.notificationListForSpecific.length>0) {
        this.notificationList.push(...this.notificationListForSpecific)
      }
      this.notificationList.push(...res.data);
      if(this.identifier){
        let uniqueIdentifierNotificationList=this.notificationList.filter((a:any)=>a.accessControlCategoryIdentifier==this.identifier)
        uniqueIdentifierNotificationList.forEach(val=>{
          val['forSpecific']='true';
        })
        this.notificationList=this.notificationList.filter((a:any)=>a.accessControlCategoryIdentifier!=this.identifier)
        this.notificationList.unshift(...uniqueIdentifierNotificationList)
      }
    },error => {
      if (error.errorAction != ErrorAction.HANDLED) {
        this.dialog.showToast(error.message, MessageType.ERROR);
      }
    })
  }

  getNotificationById(notificationId:any){
    this._notificationService.getNotificationById(notificationId).subscribe((res:any)=>{
      res['forSpecific']='true'
      this.notificationListForSpecific=[];
      this.notificationListForSpecific.push(res);
      this.getNotification();
    },error => {
      if (error.errorAction != ErrorAction.HANDLED) {
        this.dialog.showToast(error.message, MessageType.ERROR);
      }
    })
  }

  updateNotification(readStatus: boolean, id: any,identifier:any, hyperLink?: any) {
    if (identifier){
      let menuItem: any = localStorage.getItem(this.constants.byCategory);
      let sideBarMenuItem = JSON.parse(menuItem);
      sideBarMenuItem.forEach((item:any)=>{
        this.getRoutingPath(identifier,item);
      });
      this._notificationService.markAsReadSpecificNotification(id).subscribe(res=>{
        this.notificationList=this.notificationList.filter((a:any)=>a.id!=res.id)
        this.getUnreadNotificationCounts();
      },error => {
        if (error.errorAction != ErrorAction.HANDLED) {
          this.dialog.showToast(error.message, MessageType.ERROR);
        }
      });
    }
    else if(hyperLink){
        window.open(hyperLink, '_blank');
      this._notificationService.markAsReadSpecificNotification(id).subscribe(res=>{
        this.notificationList=this.notificationList.filter((a:any)=>a.id!=res.id)
        this.getUnreadNotificationCounts();
      },error => {
        if (error.errorAction != ErrorAction.HANDLED) {
          this.dialog.showToast(error.message, MessageType.ERROR);
        }
      })
        this.bsModalRef.hide();
    }
  }

  allNotificationRead(accessControlCategoryIdentifier:any=''){
    this._notificationService.markAllAsReadNotification(accessControlCategoryIdentifier).subscribe(res=>{
      res.data.forEach((val:any)=>{
        this.notificationList=this.notificationList.filter((a:any)=>a.id!=val.id)
      })
      this.getUnreadNotificationCounts();
    },error => {
      if (error.errorAction != ErrorAction.HANDLED) {
        this.dialog.showToast(error.message, MessageType.ERROR);
      }
    })
  }

  getUnreadNotificationCounts(){
    this._notificationService.getUnreadNotificationCountsByACGroup().subscribe(res=>{
      this._notificationService.updateNotificationCount(res);
    })
  }

  getRoutingPath(identifier:any,accessControlCategoryItem:any){
    if(accessControlCategoryItem.identifier==identifier){
      this.router.navigate([accessControlCategoryItem.pageUrl], {
        replaceUrl: true,
      });
      this.bsModalRef.hide();
    }
    else {
      accessControlCategoryItem.subCategories.forEach((item:any)=>{
        this.getRoutingPath(identifier,item);
      })
    }
  }
  override ngOnDestroy() {
    super.ngOnDestroy();
    this.notificationList = [];
    this.notificationListForSpecific = [];
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class KeywordsAndConstants {
  LocalStorageToken = 'Token';
  LocalStorageRefreshToken = 'RefreshToken';
  isLoginType = 'LoginType';
  LocalStorageUserInfo = 'UserInfo';
  isModalOpen = 'underMaintenanceModalOpen';
  byCategoryValue: Array<any> = [];
  accessControlItem = 'AllAccessControlItem';
  byCategory = 'byCategory';
  offsetTokenValueforForex = '';
  postalCodeIndex: number = -1;
  cityIndex: number = -1;
  expiryDateIndex: number = -1;
}

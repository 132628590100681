<!-- ======= Header ======= -->
<header id="header" class="fixed-top">
  <div class="container d-flex align-items-center">

    <h1 class="logo mr-2"><a href="../../../index.html"><img src="../../../assets/home/logo-atlpay.svg" class="img-fluid" alt=""></a></h1>
    <!-- Uncomment below if you prefer to use an image logo -->
    <!-- <a href="index.html" class="logo mr-auto"><img src="assets/logo.png" alt=""></a>-->

    <nav class="nav-menu d-none d-lg-block mx-auto">
      <ul>
<!--        <li class="active"><a href="about.html">About Us</a></li>-->
<!--        <li class="drop-down"><a href="">Solutions</a>-->
<!--          <ul>-->
<!--            <li><a href="solutions1.html">Ecommerce Payments</a></li>-->
<!--            <li><a href="solutions2.html">Backend to Backend Integration</a></li>-->
<!--            <li><a href="solutions3.html">Risk Management</a></li>-->
<!--            <li><a href="solutions4.html">Online payment page</a></li>-->
<!--          </ul>-->
<!--        </li>-->
<!--        <li><a href="pricing.html">Pricing</a></li>-->
<!--        <li><a href="#faq">FAQ</a></li>-->
<!--        <li><a href="contact.html" class="border-bottom-0">Contact</a></li>-->
        <li class="d-block  d-lg-none"><a href="login" class="">Login</a></li>
      </ul>
    </nav><!-- .nav-menu -->
    <div class="d-none d-lg-block">
      <ul class="list-unstyled"><li><a href="login" class="btn-dark" style="color: #fff;">Login</a></li></ul>
    </div>
  </div>
</header><!-- End Header -->

<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-7 order-2 order-lg-1"  data-aos="zoom-out" data-aos-delay="100">
        <h1>Accelerate your global  <span>payments</span>
        </h1>
        <h2>Commence your business with an online payment platform</h2>
<!--        <div class="d-flex">-->
<!--          <a href="#about" class="btn-dark scrollto">Get Started</a>-->
<!--        </div>-->
      </div>
      <div class="col-xl-6 col-lg-5 order-1 order-lg-2 position-relative">
        <div class="welcome-image-group-wrapper">
          <div class="welcome-image welcome-image--group-01">
            <img src="../../../assets/home/l1-hero-img-ipad.png" alt="" class="welcome-image__single welcome-image--group-01__main">
            <div class="welcome-image__single welcome-image--group-01__img-1">
              <img class="w-100" src="../../../assets/home/hero-mobile.png" alt="">
            </div>
            <div class="welcome-image__single welcome-image--group-01__img-2">
              <img class="w-100" src="../../../assets/home/l1-hero-img-2.png" alt="">
            </div>
            <!--<div class="welcome-image__single welcome-image--group-01__img-3">
              <img class="w-100" src="assets/home/l1-hero-img-3.png" alt="">
            </div>-->
            <div class="welcome-image__single welcome-image--group-01__img-4">
              <img class="w-100" src="../../../assets/home/hero-dots.png" alt="">
            </div>
            <div class="welcome-image__single welcome-image--group-01__img-5">
              <img class="w-100" src="../../../assets/home/l1-hero-shape-1.png" alt="">
            </div>

          </div><!--//welcome-image-->
        </div>
      </div><!--//col-xl-6-->
    </div><!--//row-->

  </div>
</section><!-- End Hero -->

<main id="main">

  <section class="video-section">
    <div class="container text-center">
      <h2>Worlds most powerful<br>
        and easy to use APIs</h2>
      <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="venobox btn-watch-video" data-vbtype="video" data-autoplay="true"></a>
    </div>
  </section>

  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-10">
          <h3 class="mb-5">A frictionless payment technology to drive your business growth</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <p>With leading uptime and virtually unlimited scalability, your business requires a seamless payment experience. The right payment method aids global reach by increasing conversions.</p>
        </div>
        <div class="col-md-6">
          <p>ATLPay offers a business payment platform to manage all cross-border transactions. It simplifies and tailors the process of payment according to the businesses needs. Our approach is to handle the complexities of payments for you while you focus on your business.
          </p>
        </div>
<!--        <div class="col-md-6 offset-md-3 text-center">-->
<!--          <div class="my-5">-->
<!--            <a href="#about" class="btn-dark scrollto">Learn More</a>-->
<!--          </div>-->
<!--        </div>-->
      </div><!--//row-->

      <div class="row align-items-center mt-5">
        <div class="col-md-6">
          <img src="../../../assets/home/card.svg" class="img-fluid" alt="">
        </div>
        <div class="col-md-6">
          <img src="../../../assets/home/pay-method.svg" class="img-fluid" alt="">
        </div>
      </div><!--//row-->

    </div>
  </section>

  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-8 text-center offset-md-2">
          <h3 class="mb-5">Accept payments across
            all sales channels</h3>
          <a href="#">Learns more about our products</a>
        </div>
      </div>
      <div class="row mt-100px">
        <div class="col-md-5 offset-md-1">
          <div class="position-relative">
            <img src="../../../assets/home/payment1.png" class="img-fluid w-100" alt="">
            <div class="payment-artleft">
              <img src="../../../assets/home/payment1-art.png" alt="" data-aos="fade-left" data-aos-delay="400">
            </div>
          </div>
          <h4 class="mt-4">GIVES CONTROL</h4>
          <p>Smart payment processing gives flexibility to your business by partnering with ATLPay.
          </p>
        </div>
        <div class="col-md-5">
          <h4 class="mt-4">ACHIEVE CROSS-BORDER GROWTH</h4>
          <p>Make buying easier for your customers in different countries by offering them a payment method they prefer.</p>
          <div class="position-relative">
            <img src="../../../assets/home/payment2.png" class="img-fluid w-100" alt="">
            <div class="payment-artright">
              <img src="../../../assets/home/payment2-art.png" alt="" data-aos="fade-right" data-aos-delay="500">
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="dark-bg position-relative">
    <div id="bgearth" class="earth-js"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h3 class="mb-5">Key Features</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/Transaction%20monitoring.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>Transaction monitoring</h5>
          <p class="font-weight-light">
            Facilitates payments that are legitimate and adhere to card network and government rules and regulations. Ensures smooth cross-industry experience and accurate overseeing of reports on every transaction. Also monitors the transactions that pass through its system for suspicious activity.
          </p>
        </div>
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/Global%20payment%20gateway.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>Global payment gateway</h5>
          <p class="font-weight-light">
            There is no doubt that contactless payments occur from one zone to another. Now cross borders transactions are fuss-free with our vast global acquiring network with direct connections to all major card schemes. So, ATLPay aids to accept all transactions around the world through one integration.
          </p>
        </div>
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/dynamic%20currency%20sol.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>Dynamic currency solutions</h5>
          <p class="font-weight-light">
            Enables to accept payments in various currencies worldwide. Hence fabricate the payment process at your client's fingertips. Also, it offers the convenience of paying in the local currency.
          </p>
        </div>
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/Hosted%20payment%20page.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>Hosted payment page</h5>
          <p class="font-weight-light">
            Simplifies your online payment process for business using ATLPay. We provide a credible payment page that adapts to your client's device and location to increase conversion.
          </p>
        </div>
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/Robust%20security.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>Robust Security</h5>
          <p class="font-weight-light">
            We offer your clients to convert their payments with our reliable and safe payment technology. We follow a strict commitment towards protecting personal data and privacy.
          </p>
        </div>

        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/online%20payment%20pages.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>Online payment pages</h5>
          <p class="font-weight-light">
            Our online business payment is designed for quick integration to scale up and maximise conversions. Also, it offers all the relevant payments methods and built-in features such as localisation, one-click payment and embedded risk solutions.
          </p>
        </div>
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/Innovative%20and%20flexible.svg" class="img-fluid w-25 d-block  mb-5" alt="">
          <h5>Innovative and flexible</h5>
          <p class="font-weight-light">
            Bring flexibility to your client's business by partnering with ATLPay. Our innovative payment platform for business enables your clients to conduct smart payment from anywhere around the world.
          </p>
        </div>
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/Real%20time%20updates%20and%20alerts.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>Real-time updates and alerts</h5>
          <p class="font-weight-light">
            enhances your client's business in a better and easier way. The transactions and automate reports can be viewed in CSV, XLS or PDF format. Adding
          </p>
        </div>
      </div>
    </div>
  </section>


  <section class="security-section">
    <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <div class="container">
      <div class="row pb-5">
        <div class="col-md-6 offset-md-3">
          <img src="../../../assets/home/security.svg" class="img-fluid" alt="">
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4">
          <p class="security-text">
            <b>Data encryption</b><br>  It is the primary security method used by the payment gateways to safeguard money. Your client's data can be secured and encrypted only by our payment gateway. With the help of using an algorithm, the payment gateway assures that no unauthorized party can decrypt the information.
          </p>
        </div>
        <div class="col-md-4">
          <p class="security-text">
            <b>Secure Socket Layer (SSL)</b><br> This security protocol allows to establish an encrypted link between the server and the browser. SSL protects sensitive information for an e-commerce website. We inspire the worldwide to make an online transaction with websites that are SSL certified.
          </p>
        </div>
        <div class="col-md-4">
          <p class="security-text">
            <b>Secure Electronic Transaction (SET) </b><br>  Used to secure transmission of customer's card details during an online transaction. Hence prevents merchants and hackers from accessing the sensitive information of the customers. It also uses customer's digital signatures for authentication and to maintain confidentiality.
          </p>
        </div>
      </div>
    </div>
  </section>


  <section class="dark-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h3 class="mb-5">Our Services</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/Risk%20management.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>Risk Management</h5>
          <p class="font-weight-light">
            With our flexible Rules Engine, it empowers your client's businesses to design a highly customised fraud screening profile adapted to your specific needs. Also, it addresses any fraudulent activity and chargebacks while boosting sales. Monitor your client's performance and make adjustments so that you can spot fraud early and at more stages.
          </p>
        </div>
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/E-commerce.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>E-Commerce</h5>
          <p class="font-weight-light">
            We have created a frictionless commerce experience based on the latest technology. Our business payment gateway accepts all debit cards and credit cards purchased in-store, online, mobile, in-app, mail/phone purchase or pay by link payment.
          </p>
        </div>
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/online%20payment%20pages.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>Online payment pages</h5>
          <p class="font-weight-light">
            Our online business payment is designed for quick integration to scale up and maximise conversions. Also, it offers all the relevant payments methods and built-in features such as localisation, one-click payment and embedded risk solutions.
          </p>
        </div>
        <div class="col-md-3 mb-4">
          <img src="../../../assets/home/Backend%20integration.svg" class="img-fluid w-25 d-block mb-5" alt="">
          <h5>Backend to backend integration</h5>
          <p class="font-weight-light">
            APIs have become increasingly popular tools in today's scenario. API builds a connection between a set of automated trading algorithms and the trader's preferred trading broker platform. Hence it serves the purpose of real-time quotes and pricing data.
          </p>
        </div>

      </div>
    </div>
  </section>

  <section class="why-section">
    <div class="container">
      <div class="row">
        <div class="col-md-10">
          <h4 class="theme-color mb-4">Why ATLPay</h4>
          <h3 class="mb-5">A technology-first approach to payments
            and finance</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <h5>Fast setup of payment gateway</h5>
          <p class="font-weight-light">Enables you to build an unrivaled commerce experience. Also provides the best payment journey for you on the latest technology.</p>
        </div>
        <div class="col-md-3">
          <h5>Designed for smooth e-commerce</h5>
          <p class="font-weight-light">By unifying all sales channels through a seamless payment journey on the latest technology like ATLPay. It helps to increase your conversion rates.</p>
        </div>
        <div class="col-md-3">
          <h5>Easy for businesses to integrate</h5>
          <p class="font-weight-light">Without compromising on the checkout experience. Hence gives an extra point to customer satisfaction for hassle-free payments.</p>
        </div>
        <div class="col-md-3">
          <h5>Enhances your business growth</h5>
          <p class="font-weight-light">Launch new markets and add customer’s preferred way of paying. With ATLPay’s it is possible to increase conversion abroad.</p>
        </div>
        <div class="col-md-3">
          <h5>Reliable and safe online business payment platform</h5>
          <p class="font-weight-light">The power to process even peak-volumes events with extreme traffic. Also ensures the utmost security by adapting strict measures.</p>
        </div>
        <div class="col-md-3">
          <h5>24*7 personal assistance</h5>
          <p class="font-weight-light">Our squad will be present to address your query regarding payments. Adding on we also extend this service in various local languages.</p>
        </div>
        <div class="col-md-3">
          <h5>Accepts payments in diverse currencies</h5>
          <p class="font-weight-light">As stated above that we are globally present, we do accept funds from different countries.</p>
        </div>
        <div class="col-md-3">
          <h5>Accessible from anywhere</h5>
          <p class="font-weight-light">Expanding new channels from one single payment partner that is ATLPay. Also balances and manages your business during a crisis.</p>
        </div>
      </div>
    </div>
  </section>
  <section class="journey-section">
    <div class="container text-center">
      <div class="col-md-12">
        <h2 class="text-white my-5">Start your journey with <br>
          ATLPay today</h2>
      </div>
    </div>
  </section>

  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-3 mb-4">
          <h3>Latest<br>News<span class="news-dot"></span></h3>
        </div>
        <div class="col-md-3">
          <a href="#" class="news-block d-block">
            <h4 class="font-weight-normal mb-4">Lorem Ipsum is simply</h4>
            <p>Lorem Ipsum is simply dummy
              text of the printing and
              typesetting industry. Lorem
              Ipsum has been the industry's
              standard .</p>
          </a>

        </div>
        <div class="col-md-3">
          <a href="#" class="news-block d-block">
            <h4 class="font-weight-normal mb-4">Lorem Ipsum is simply</h4>
            <p>Lorem Ipsum is simply dummy
              text of the printing and
              typesetting industry. Lorem
              Ipsum has been the industry's
              standard .</p>
          </a>
        </div>
        <div class="col-md-3">
          <a href="#" class="news-block d-block">
            <h4 class="font-weight-normal mb-4">Lorem Ipsum is simply</h4>
            <p>Lorem Ipsum is simply dummy
              text of the printing and
              typesetting industry. Lorem
              Ipsum has been the industry's
              standard .</p>
          </a>
        </div>
      </div>
    </div>
  </section>
</main><!-- End #main -->

<!-- ======= Footer ======= -->
<footer id="footer">

  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-3 col-md-6 footer-contact">
          <h3 class="foot-logo"><img src="../../../assets/home/logo-atlpay.svg" class="img-fluid" alt=""></h3>
          <p>
            ATL House, 128 Peckham Hill St,<br>
            Peckham, London SE15 5JT,<br>
            United Kingdom <br><br>
            <strong>Phone:</strong> +44 20 3137 8850<br>
          </p>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">FAQ</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Blog</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Contact Us</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Terms & Conditions</a></li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Our Solutions</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Ecommerce Payments</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Backend to Backend Integration</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Risk Management</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Online payment page</a></li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Our Social Networks</h4>
          <div class="social-links mt-3">
            <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
            <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            <a href="#" class="linkedin"><i class="bx bxl-youtube"></i></a>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="container py-4 text-center">
    <div class="copyright">
      &copy; Copyright <strong><span>ATLPay</span></strong>. All Rights Reserved
    </div>

  </div>
</footer><!-- End Footer -->
<div id="preloader"></div>
<a class="back-to-top" (click)="scrollToTop()"><i class="icofont-simple-up"></i></a>

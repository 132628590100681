import {Component, Output, EventEmitter, Input, OnInit} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import {ModalArguments} from "../../models/modal-arguments";
import {ModalButtonType} from "../../enums/modal_button_type";
import {MessageType} from "../../enums/message-type";

@Component({
  selector: 'app-error-message-modal',
  template: `
      <div class="modal-header border-bottom-0">
        <h5 class="modal-title" id="staticBackdropLabel">{{ headingTitle }}</h5>
      </div>
      <div class="modal-body">
        <p>{{message}}</p>
      </div>
      <div class="modal-footer border-top-0" *ngIf="buttonType">
        <button type="button" class="btn btn-secondary waves-effect" (click)="buttonNegativeClicked()">{{ buttonOneText }}</button>
        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="buttonPositiveClicked()">{{ buttonTwoText }}</button>
      </div>
      <div class="modal-footer border-top-0" *ngIf="!buttonType">
        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="buttonSingleClicked()">{{ singleButtonText }}</button>
      </div>
  `,
  styles: [``],
})
export class ErrorMessageModalComponent implements OnInit{
  @Input() modalArguments: ModalArguments | undefined;
  message: any;
  modalButton: any;
  buttonType!: boolean;
  Color: any;
  headingTitle: any;
  buttonOneText: any;
  buttonTwoText: any;
  singleButtonText: any;
  messageType: any;
  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.message = this.modalArguments?.message;
    this.modalButton = this.modalArguments?.buttonType;
    this.headingTitle = this.modalArguments?.title;
    this.buttonOneText = this.modalArguments?.buttonNegativeText;
    this.buttonTwoText = this.modalArguments?.buttonPositiveText;
    this.singleButtonText = this.modalArguments?.buttonSingleText;
    if (this.modalButton == ModalButtonType.DOUBLE_BUTTON) {
      this.buttonType = true;
    } else {
      this.buttonType = false;
    }
  }

  dismiss() {
    this.bsModalRef.hide();
  }

  buttonNegativeClicked() {
    if (this.modalArguments?.dismissButtonNegativeOnClick) {
      this.dismiss();
    }
    this.modalArguments?.callback?.buttonNegativeClicked();
  }

  buttonPositiveClicked() {
    if (this.modalArguments?.dismissButtonPositiveOnClick) {
      this.dismiss();
    }
    this.modalArguments?.callback?.buttonPositiveClicked();
  }

  buttonSingleClicked() {
    if (this.modalArguments?.dismissButtonSingleOnClick) {
      this.dismiss();
    }
    this.modalArguments?.callback?.buttonSingleClicked();
  }


}

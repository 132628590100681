import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { Apis } from '../../core/apis';
import { environment } from '../../environments/environment';
import { GenericListResponse } from '../../models/generic-list-response';
import { Subject } from 'rxjs';

const API_NOTIFICATION_CONFIG_URL = Apis.allNotificationConfigList;
const API_UPDATE_NOTIFICATION_CONFIG_URL = Apis.updateNotificationConfig;
const API_BANNER_TYPE_URL = Apis.notificationBannerType;
const API_BANNER_DESCRIPTION_URL = Apis.notificationBannerDestination;
const API_NOTIFICATION_LIST_URL = Apis.getNotification;
const API_NOTIFICATION_BY_ID_URL = Apis.getNotificationById;
const API_BANNER_NOTIFICATION_URL = Apis.getBannerNotification;
const API_MARK_AS_READ_SPECIFIC_NOTIFICATION_URL =
  Apis.markAsReadSpecificNotification;
const API_MARK_ALL_AS_READ_NOTIFICATION_URL = Apis.markAllAsReadNotification;
const API_UNREAD_NOTIFICATION_COUNTS_URL =
  Apis.getUnreadNotificationCountsByACGroup;
const API_CLASS_STRUCTURE_FOR_NOTIFICATION_URL = Apis.getClassStructure;
const API_NOTIFICATION_CONFIG_BY_ID_URL = Apis.getNotificationConfigById;
const API_ADD_NOTIFICATION_MANUALLY_URL = Apis.addNotificationManually;
const API_MAY_READ_NOTIFICATIONS_URL = Apis.mayReadNotifications;
const API_ADMIN_NOTIFICATION_URL = Apis.adminNotificationList;
const API_Notification_Read_Status_Type = Apis.notificationReadStatusType;
const API_FOR_ADMIN_MERCHANT_URL = Apis.getAdminMerchantList;

const API_Limit = environment.dataLimit;
@Injectable({
  providedIn: 'root',
})
export class NotificationService implements OnDestroy {
  constructor(private http: HttpClient) {}

  callbackResponseForNotificationCount = new Subject();

  updateNotificationCount(val: any) {
    this.callbackResponseForNotificationCount.next(val);
  }
  getNotificationConfig(): Observable<GenericListResponse<any>> {
    let baseURL = `${API_NOTIFICATION_CONFIG_URL}?limit=${API_Limit}`;
    return this.http.get<GenericListResponse<any>>(baseURL);
  }
  getAdminNotificationList(
    formOp: any = '',
    offsetToken: string = ''
  ): Observable<GenericListResponse<any>> {
    let baseURL = '';
    baseURL = `${API_ADMIN_NOTIFICATION_URL}?limit=${API_Limit}`;
    if (Object.keys(formOp).length > 0) {
      let urlParameters = Object.entries(formOp)
        .map((e) => e.join('='))
        .join('&');
      baseURL = `${baseURL}&${urlParameters}`;
    }
    if (offsetToken) {
      baseURL = `${baseURL}&offsetToken=${offsetToken}`;
    }
    return this.http.get<GenericListResponse<any>>(baseURL);
  }

  updateNotificationConfig(data: any): Observable<any> {
    return this.http.put<any>(`${API_UPDATE_NOTIFICATION_CONFIG_URL}`, data);
  }

  getBannerType(): Observable<GenericListResponse<any>> {
    return this.http.get<any>(`${API_BANNER_TYPE_URL}`);
  }
  getBannerDestination(): Observable<GenericListResponse<any>> {
    return this.http.get<any>(`${API_BANNER_DESCRIPTION_URL}`);
  }
  getNotificationList(
    notificationReadStatusType: string
  ): Observable<GenericListResponse<any>> {
    let baseURL = `${API_NOTIFICATION_LIST_URL}?limit=${API_Limit}`;
    if (notificationReadStatusType) {
      baseURL = `${baseURL}&notificationReadStatusType=${notificationReadStatusType}`;
    }
    return this.http.get<any>(baseURL);
  }
  getNotificationReadStatusType(): Observable<GenericListResponse<any>> {
    return this.http.get<any>(`${API_Notification_Read_Status_Type}`);
  }
  getAllMerchant(
    offsetToken: string = '',
    searchTerm: any = null,
    alreadyLinkedToOrgStructure = null
  ): Observable<any> {
    let baseURL = `${API_FOR_ADMIN_MERCHANT_URL}?limit=${API_Limit}`;
    if (offsetToken) {
      baseURL = `${baseURL}&offsetToken=${offsetToken}`;
    }
    if (searchTerm) {
      baseURL = `${baseURL}&queryString=${searchTerm}`;
    }
    if (
      alreadyLinkedToOrgStructure == true ||
      alreadyLinkedToOrgStructure == false
    ) {
      baseURL = `${baseURL}&alreadyLinkedToOrgStructure=${alreadyLinkedToOrgStructure}`;
    }
    return this.http.get<any>(baseURL);
  }

  getNotificationById(
    notificationId: any
  ): Observable<GenericListResponse<any>> {
    return this.http.get<any>(
      `${API_NOTIFICATION_BY_ID_URL}/${notificationId}`
    );
  }
  getBannerNotification(): Observable<GenericListResponse<any>> {
    return this.http.get<any>(`${API_BANNER_NOTIFICATION_URL}`);
  }
  markAsReadSpecificNotification(notificationId: any): Observable<any> {
    return this.http.post<any>(
      `${API_MARK_AS_READ_SPECIFIC_NOTIFICATION_URL}`,
      {
        notificationId: notificationId,
      }
    );
  }
  markAllAsReadNotification(
    accessControlCategoryIdentifier: any
  ): Observable<any> {
    return this.http.post<any>(`${API_MARK_ALL_AS_READ_NOTIFICATION_URL}`, {
      accessControlCategoryIdentifier: accessControlCategoryIdentifier,
    });
  }
  getUnreadNotificationCountsByACGroup(): Observable<any> {
    return this.http.get<any>(`${API_UNREAD_NOTIFICATION_COUNTS_URL}`);
  }
  getClassStructureForNotification(
    notificationConfigId: any
  ): Observable<GenericListResponse<any>> {
    return this.http.get<any>(
      `${API_CLASS_STRUCTURE_FOR_NOTIFICATION_URL}/${notificationConfigId}`
    );
  }
  getNotificationConfigById(
    notificationConfigId: any
  ): Observable<GenericListResponse<any>> {
    return this.http.get<any>(
      `${API_NOTIFICATION_CONFIG_BY_ID_URL}/${notificationConfigId}`
    );
  }
  addNotificationManually(data: any): Observable<any> {
    return this.http.post<any>(`${API_ADD_NOTIFICATION_MANUALLY_URL}`, data);
  }
  mayReadNotifications(data: any): Observable<any> {
    return this.http.post<any>(`${API_MAY_READ_NOTIFICATIONS_URL}`, data);
  }
  ngOnDestroy() {}
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NotificationModalComponent} from "../../pages/common/notification-modal/notification-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
@Component({
  selector: '[recursive]',
  template: `
    <li *ngFor="let subitem of children">
      <a routerLink="{{subitem.pageUrl}}" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
         [attr.data-parent]="subitem.identifier" routerLinkActive="active" (click)="openNotificationModal(subitem)">
        <span> {{ subitem.title }}</span>
        <span class="badge rounded-pill float-end bg-primary" *ngIf="subitem.unreadCount">{{subitem.unreadCount}}</span>
      </a>
      <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
         [attr.data-parent]="subitem.identifier" (click)="openNotificationModal(subitem)">
        <span> {{ subitem.title }}</span>
        <span class="badge rounded-pill float-end bg-primary" *ngIf="subitem.unreadCount">{{subitem.unreadCount}}</span>
      </a>
      <ul recursive [children]="subitem.subCategories" *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false"></ul>
<!--      <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">-->
<!--        <li *ngFor="let subSubitem of subitem.subCategories">-->
<!--          <a [attr.data-parent]="subSubitem.identifier" routerLink="{{subSubitem.pageUrl}}" routerLinkActive="active"-->
<!--             class="side-nav-link-ref">-->
<!--            {{ subSubitem.title }}-->
<!--          </a>-->
<!--        </li>-->
<!--      </ul>-->
    </li>
  `,
  styles: [
    `
    `,
  ],
})
export class RecursiveMenuItemComponent implements OnInit, OnDestroy{
  @Input() title!: number;
  @Input() label!: string;
  @Input() children!: any[];
  @Input() parent: any;
  bsModalRef?: BsModalRef;
  self = this;
  constructor(private router: Router,
              private modalService:BsModalService) {
  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  hasItems(item: any) {
    return item.subCategories !== undefined ? item.subCategories.length > 0 : false;
  }

  openNotificationModal(val:any){
    if(val.unreadCount){
      this.bsModalRef= this.modalService.show(NotificationModalComponent,{
        class: 'right-side-modal-class',
      })
      this.bsModalRef.content.identifier = val.identifier;
    }
  }
  ngOnDestroy() {
  }
}

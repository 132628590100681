import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {KeywordsAndConstants} from '../../core/keywords-and-constants';
import {NotificationService} from "./notification.service";

@Injectable({
  providedIn: 'root',
})
export class AccessControlService {
  CategoryMethod: Array<any> = [];
  byCategoryMethod: any;
  checkCategoryCallBack = (routeName: any, componentCallback?: any): void => {
    if (this.byCategoryMethod)
      this.CategoryMethod = JSON.parse(this.byCategoryMethod);
    if (this.CategoryMethod.find((o) => o === routeName)) {
    } else {
      this.router.navigate(['/dashboard']);
    }
  };

  checkCategoryByButtonCallBack = (
    routeName: any,
    componentCallback?: any
  ): void => {
    if (this.byCategoryMethod) {
      this.CategoryMethod = JSON.parse(this.byCategoryMethod);
    }

    if (this.CategoryMethod.find((o) => o === routeName)) {
      if (componentCallback != null) componentCallback(true);
    } else {
      if (componentCallback != null) componentCallback(false);
    }
  };

  constructor(
    private constants: KeywordsAndConstants,
    private router: Router,
    private _notificationService: NotificationService,
  ) {
  }

  checkCategory(data: any) {
    this.initializedata(this.checkCategoryCallBack, data);
  }

  checkCategoryByButton(val: any, componentCallback?: any) {
    return this.initializedata(
      this.checkCategoryByButtonCallBack,
      val,
      componentCallback
    );
  }

  initializedata(clBack: any, routeName: any, componentCallback?: any) {
    if (this.byCategoryMethod != undefined) {
      clBack(routeName, componentCallback);
      return;
    }
    var StoreByCategoryData = localStorage.getItem(
      this.constants.accessControlItem
    );
    if (StoreByCategoryData == undefined || StoreByCategoryData == null) {
      setTimeout(() => {
        this.initializedata(clBack, routeName, componentCallback);
      }, 100);
    } else {
      this.byCategoryMethod = localStorage.getItem(
        this.constants.accessControlItem
      );
      clBack(routeName, componentCallback);
    }
  }

  mayReadNotification(refIds: any) {
    if (refIds.length>0) {
      this._notificationService.mayReadNotifications({refIds: refIds}).subscribe(res => {
      })
    }
  }
}

import { Component, Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { KeywordsAndConstants } from '../../../core/keywords-and-constants';
import { Apis } from '../../../core/apis';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../../network/services/local-storage.service';
import { TokenCallbackService } from '../../../network/services/token-callback.service';

@Injectable()
@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnDestroy {
  intervalId: any;

  initialRefreshTokenCheckingDone: boolean = false;

  pageApisCalled: boolean = false;
  httpSubscription: Subscription | null = null;

  constructor(
    public http: HttpClient,
    public constants: KeywordsAndConstants,
    public _service: LocalStorageService,
    public _callBackService: TokenCallbackService,
    public _callingForSideBar: Boolean = false
  ) {
    this.intervalId = setInterval(() => {
      this.refreshToken();
    }, 1000 * 30);
    if (!_callingForSideBar) {
      this.refreshToken();
    }
  }

  canCallPageApis(): boolean {
    return this.initialRefreshTokenCheckingDone;
  }

  refreshToken() {
    const token: any = localStorage.getItem(this.constants.LocalStorageToken);
    if (token) {
      const expTimeStamp = Number(
        new JwtHelperService().decodeToken(token).exp
      );
      const currentTimeStamp = Number(
        new Date().getTime().toString().slice(0, 10)
      );
      const res = Number(expTimeStamp) - Number(currentTimeStamp);
      if (res < 5) {
        if (!this._callBackService.refreshOnProgress) {
          this._callBackService.refreshOnProgress = true;
          // @ts-ignore
          this.httpSubscription = this.http
            .get(Apis.refreshToken, {
              headers: new HttpHeaders({
                'Access-Control-Allow-Origin': environment.allowed_origin,
                Authorization: `Bearer ${this._service.getRefreshToken()}`,
              }),
            })
            .subscribe(
              (refreshTokenResponse: any) => {
                if (refreshTokenResponse) {
                  localStorage.setItem(
                    this.constants.LocalStorageToken,
                    JSON.stringify(refreshTokenResponse.token)
                  );
                  localStorage.setItem(
                    this.constants.LocalStorageRefreshToken,
                    JSON.stringify(refreshTokenResponse.refreshToken)
                  );
                  this._service.backUpToken = JSON.stringify(
                    refreshTokenResponse.token
                  );
                  this._service.backUpRefreshToken = JSON.stringify(
                    refreshTokenResponse.refreshToken
                  );
                  const helper = new JwtHelperService();
                  const decodedToken = helper.decodeToken(
                    refreshTokenResponse.token
                  );
                  localStorage.setItem(
                    this.constants.isLoginType,
                    decodedToken.type
                  );
                }
              },
              null,
              () => {
                this.initialRefreshTokenCheckingDone = true;
                this._callBackService.refreshOnProgress = false;
                this._callBackService.refreshTokenForPageIsDone(true);
                this.httpSubscription?.unsubscribe();
              }
            );
        }
      } else {
        this.initialRefreshTokenCheckingDone = true;
        this._callBackService.refreshTokenForPageIsDone();
      }
    } else {
      this.initialRefreshTokenCheckingDone = true;
      this._callBackService.refreshTokenForPageIsDone();
    }
  }

  ngOnDestroy() {
    if (this.httpSubscription != null) {
      this.httpSubscription.unsubscribe();
    }
    clearInterval(this.intervalId);
  }
}

<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of sideBarMenuItem">
        <li *ngIf="item.title">
          <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
             [ngClass]="{'has-arrow': item.subCategories.length>0, 'has-dropdown': item.subCategories.length==0}" (click)="openNotificationModal(item)">
            <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
            <span> {{ item.title }}</span>
            <span class="badge rounded-pill float-end bg-primary" *ngIf="item.unreadCount">{{item.unreadCount}}</span>
          </a>

          <a routerLink="{{item.pageUrl}}" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active" (click)="openNotificationModal(item)">
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.title  }}</span>
            <span class="badge rounded-pill bg-primary float-end" *ngIf="item.unreadCount">{{item.unreadCount}}</span>
          </a>
          <ul recursive [children]="item.subCategories" *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false"></ul>
        </li>

      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->

import { Injectable } from "@angular/core";
import { ModalArguments } from "src/models/modal-arguments";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ErrorMessageModalComponent} from "../app/pages/error-message-modal.component";
import {MessageType} from "../enums/message-type";
import {Observable} from "rxjs/internal/Observable";
import {IndividualConfig, ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root',
})
export class DialogUtil {
   bsModalRef!: BsModalRef;
    constructor(
      private bsModalService: BsModalService,
      private toastService:ToastrService
      ) {}

  showToast(message: string, messageType: MessageType = MessageType.INFO):Observable<any> {
    let toReturnObservable : Observable<any>;
    let options: Partial<IndividualConfig> = {};
    switch (messageType) {
      case MessageType.INFO: {
        options = { ...options, timeOut: 3000 };
        return this.toastService.info(message, '', options).onShown;
      }
      case MessageType.WARNING: {
        options = { ...options, timeOut: 3000 };
        return this.toastService.warning(message, '', options).onShown;
      }
      case MessageType.SUCCESS: {
        options = { ...options, timeOut: 3000 };
        return this.toastService.success(message, '', options).onShown;
      }
      case MessageType.ERROR: {
        options = { ...options, timeOut: 3000 };
        return this.toastService.error(message, '', options).onShown;
      }
    }
  }

  errorShowModal(modalArguments: ModalArguments){
    this.bsModalRef = this.bsModalService.show(ErrorMessageModalComponent,modalArguments.cancellable
      ? {
        initialState: { modalArguments },
        class: 'modal-dialog-centered'
      }
      : {
        initialState: { modalArguments },
        class: 'modal-dialog-centered',
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: true
      });
   }
}
  export interface ModalButtonClickCallbackContract {
    buttonNegativeClicked(): void;
    buttonPositiveClicked(val1?:any,val2?:any): void;
    buttonSingleClicked(): void;
  }

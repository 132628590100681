<div class="modal-body p-0">
  <div class="dropdown-menu-lg dropdown-menu-end p-0">
    <div class="p-3">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="m-0">Notifications</h6>
        </div>
        <div class="col-auto">
          <a href="javascript: void(0);" class="small" (click)="allNotificationRead()">Mark All As Read </a>
        </div>
      </div>
    </div>
    <ngx-simplebar style="position: relative; min-height: 100px;max-height: 230px;" *ngIf="notificationList.length>0">
      <div *ngFor="let val of notificationList;let i=index">
        <a href="javascript: void(0);" class="text-reset notification-item" (click)="updateNotification(val.readStatus,val.id,val.accessControlCategoryIdentifier,val.hyperLinkToPage)" >
          <div class="d-flex" [ngStyle]="val.forSpecific ?{'background-color':'#d5ddf5'}:{'background-color':'none'}">
<!--            <div class="avatar-xs me-3">-->
<!--                  <span class="avatar-title bg-primary rounded-circle font-size-16">-->
<!--                    {{i+1}}-->
<!--                  </span>-->
<!--            </div>-->
            <div class="flex-grow-1">
              <h6 class="mb-1">{{ val.message }}</h6>
              <div class="font-size-12 text-muted">
                <!--                    <p class="mb-1">w</p>-->
                <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{val.createdDate |date :'MMM d y h:mm a'}}</p>
              </div>
            </div>
          </div>
        </a>
      </div>
      <!--            <a href="javascript: void(0);" class="text-reset notification-item">-->
      <!--              <div class="d-flex">-->
      <!--                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">-->
      <!--                <div class="flex-grow-1">-->
      <!--                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>-->
      <!--                  <div class="font-size-12 text-muted">-->
      <!--                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>-->
      <!--                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' |-->
      <!--                      translate}}</p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </a>-->
    </ngx-simplebar>
  </div>
</div>

import { Injectable } from '@angular/core';

@Injectable()
export class StringVariable {
  public dashboard: any = 'Dashboard';
  public settings: any = 'Settings';
  public updateAccessControlCategoryOrder: any =
    'Updated Access Control Category Order';
  public name: any = 'Name';
  public identifier: any = 'Identifier';
  public shouldShow: any = 'ShouldShow';
  public isNonAuthApi: any = 'Is Non Auth Api';
  public nonAuthApiUrl: any = 'Non Auth Api Url';
  public stepVerification: any = '2-Step Verification';
  public alreadyHaveAnOtp: any = 'Already Have An Otp';
  public sendOtp: any = 'Send OTP';
  public resendbtn: any = 'Resend Otp';
  public buttonLogout: any = 'Logout';
  public submitbtn: any = 'Submit';
  public filterbtn: any = 'Filter';
  public notificationConfigType: any = 'Notification Type';
  public twoFactorLabel: any =
    'Scan this QR code using your time based OTP authenticator app';
  public authenticationApps: any = 'Authentication Apps';
  public donebtn: any = 'Done';

  public email: any = 'Email';
  public parentTag: any = 'Parent Tag';
  public otpDeliveryChannel: any = 'Otp Delivery Channel';
  public password: any = 'Password';
  public newPassword: any = 'New Password';
  public currentPassword: any = 'Current Password';
  public changePassword: any = 'Change Password';
  public message: any = 'Message';
  public readStatus: any = 'Read Status';
  public currentDevice: any = 'Current Device';
  public country: any = 'Country';
  public countryOfficialName: any = 'Official Name';
  public code: any = 'Code';
  public countryAlpha2: any = 'Alpha2';
  public countryAlpha3: any = 'Alpha3';
  public serviceable: any = 'Serviceable';
  public businessStakeHoldersId: any =
    'Business Stake Holders / UBOs Identifier text';
  public accessRole: any = 'Access Role';
  public mobileNumber: any = 'Mobile Number';
  public otpSender: any = 'OTP Sender Implementation';
  public addressSchema: any = 'Address Schema';
  public businessIdentificationFieldSchema: any =
    'Business Identification Field Schema';
  public individualIdentifierFieldSchema: any =
    'Individual Identification Field Schema';
  public documentCategories: any = 'Document Categories';
  public stakeHolderDocumentCategory: any = 'Stake Holder Document Category';
  public actionAfterExpiry: any = 'Action After Expiry';
  public expiryWarningBufferDays: any = 'Expiry Warning Buffer Days';
  public mandatory: any = 'Mandatory';
  public expirable: any = 'Expirable';
  public categoryName: any = 'Category Name';
  public stakeHoldersIdText: any = 'Stake Holders';

  public fieldName: any = 'Field Name';
  public fieldType: any = 'Field Type';
  public fieldRegex: any = 'Field Regex';
  public order: any = 'Order';
  public fieldDescription: any = 'Field Description';
  public fieldHint: any = 'Field Hint';
  public selectOptions: any = 'Select Options';
  public selectOptionValue: any = 'Select Option Value';
  public selectOptionDescription: any = 'Select Option Description';
  public hasToBeUnique: any = 'HasTo Be Unique';
  public postalCode: any = 'Postal Code';
  public cityOrTown: any = 'City Or Town';
  public fieldSchemaFieldSelectOptionType: any =
    'Field Schema Field Select Option Type';
  public cmplxFldOrientation: any = 'Complex Field Orientation';
  public mask: any = 'Mask';
  public selectQuery: any = 'Select Query';
  public exQuery: any =
    "select * from execute_global_field_queries('select isd_code::text, known_name::text from countries limit 50') 1st Param - Value, 2nd Param - Description";
  public areaWghtOnCmplxFld: any = 'Area Weight On Complex Field';
  public repeatMinTimes: any = 'Repeat Min Times';
  public expiryDateField: any = 'Expiry Date Field';

  public title: any = 'Title';
  public description: any = 'Description';
  public next: any = 'Next';
  public previous: any = 'Previous';
  public basicInfo: any = 'Basic Info';
  public businessInfo: any = 'Business Info';
  public bussinessDocument: any = 'Business Documents';
  public stakeHoldersInfo: any = 'Stake holders info';
  public address: any = 'Address';
  public taxInfo: any = 'Tax info';
  public currencyConfiguration: any = 'Currency Configuration';
  public contactInformation: any = 'Contact Information';
  public settlementAccounts: any = 'Settlement Accounts';
  public merchantSettlement: any = 'Merchant Settlement';
  public walletSettlementStatusType: any = 'Wallet Settlement Status Type';
  public howManyTimes: any = 'How Many Times';
  public mrchntStlmtAccCnfgRactvtAftTmUnt: any =
    'Merchant Settlement Account Config Reactivation Duration';
  public reactivateAfterTimeValue: any = 'Reactivate After Time Value';
  public notes: any = 'Notes ';

  public paymentPage: any = 'Payment Page';
  public webhooks: any = 'Webhooks';
  public transactionState: any = 'Transaction State';
  public wallets: any = 'Wallets';
  public walletDetails: any = 'Wallet Details';
  public walletCode: any = 'Wallet Code';
  public balance: any = 'Balance';
  public days: any = 'Days';
  public ratio: any = 'Ratio';

  public accessControl: any = 'Access Control';
  public columnControl: any = 'Column Control';
  public method: any = 'Method';
  public httpMethod: any = 'Http Method';
  public mobileAndEmailVerification: any = 'Mobile and Email Verification';
  public action: any = 'Action';
  public emailBodyRabbitMqReq: any = 'Email Body Request';
  public submitBtn: any = 'Submit';
  public emailSent: any = 'Email Sent';
  public more: any = 'More...';
  public emailLabel: any = 'Email address';
  public phnumber: any = 'Mobile Number';
  public verify: any = 'Verify';
  public detail: any = 'Detail';
  public implName: any = 'Implementation Name';
  public forexFeederRunIntervalUnit: any = 'Forex Feeder Run Interval Unit';
  public feederRunIntervalValue: any = 'Feeder Run Interval Value';
  public rateOfRequestPerMinute: any = 'Rate Of Request Per Minute';
  public forexFeederInsertPolicy: any = 'Forex Feeder Insert Policy';
  public currencyFrom: any = 'Currency From';
  public currencyTo: any = 'Currency To';
  public forexMarginPolicy: any = 'Forex Margin Policy';
  public marginValue: any = 'Margin Value';
  public marginPercentage: any = 'Margin %';

  public isAuthApi: any = 'Is Auth Api';
  public apiTire: any = 'Api Tire';

  public userType: any = 'User Type';
  public userId: any = 'User Id';
  public user: any = 'User';
  public admin: any = 'Admin';
  public devices: any = 'Devices';
  public clientDevices: any = 'Client Device';
  public api: any = 'Apis';
  public fromDate: any = 'From Date';
  public toDate: any = 'To Date';
  public dateRangeType: any = 'Date Range Type';
  public notificationReadStatusType: any = 'Notification Read Status Type';
  public sessionTracking: any = 'Session Tracking Id';
  public databaseAccessLogs: any = 'Database Access Logs';
  public isSystemGeneratedRequest: any = 'Is System Generated Request';
  public isExternalRequest: any = 'Is External Request';
  public changedFieldName: any = 'Changed Field Name';
  public valueBeforeChange: any = 'Value Before Change';
  public valueAfterChange: any = 'Value After Change';
  public tableName: any = 'Table Name';
  public isFoundAtCache: any = 'Is Found At Cache';
  public initialData: any = 'Initial Data';
  public finalData: any = 'Final Data';
  public operationType: any = 'Operation Type';
  public notificationType: any = 'Notification Type';

  public feeder: any = 'Feeder';
  public insertedVia: any = 'Inserted Via';
  public approvalStatus: any = 'Approval Status';
  public rate: any = 'Rate';
  public expiryType: any = 'Expiry Type';
  public effectiveFromDate: any = 'Effective From Date';
  public effectiveTillDate: any = 'Effective Till Date';
  public effectiveFromNow: any = 'Effective From Now';

  public emailIdToImpersonate: any = 'Email Id To Impersonate';
  public skipToDashboard: any = 'Skip To Dashboard';
  public otp: any = 'OTP';

  public documents: any = 'Documents';
  public docsName: any = 'Docs Name';
  public fileType: any = 'File Type';
  public createdDate: any = 'Created Date';

  public transactionPendingWebhooks: any = 'Transaction Pending Webhooks';
  public merchantPendingWebhooks: any = 'Merchant Pending Webhooks';

  public miscParamsForPricing: any = 'Misc Params For Pricing';
  public view: any = 'View';
  public currency: any = 'Currency';
  public instrumentType: any = 'Instrument Type';
  public subInstrumentType: any = 'SubInstrument Type';
  public instrumentClassName: any = 'Instrument Class Name';
  public attribute: any = 'Attribute';
  public forAllCurrencies: any = 'For All Currencies';
  public forAllInstrument: any = 'For All Instrument';
  public forAllSubInstruments: any = 'For All Sub Instrument';
  public transactionRate: any = 'Transaction Rate';
  public refundRate: any = 'Refund Rate';
  public chargeBackRate: any = 'Charge Back Rate';
  public chargeRefundInCaseOfRefund: any = 'Charge Refund In Case Of Refund';
  public authorizeAndCaptureDelayPossible: any =
    'Authorize And Capture Delay Possible';
  public transactionCaptureAfterHours: any = 'Transaction Capture After Hours';
  public threeDSAvailable: any = 'Three DS Available';
  public costingWeight: any = 'Costing Weight';
  public reliabilityWeight: any = 'Reliability Weight';
  public speedWeight: any = 'Speed Weight';
  public minAmount: any = 'Min Amount';
  public maxAmount: any = 'Max Amount';
  public implClassName: any = 'Implementation Class Name';
  public attributesFromExistingData: any = 'Attributes From Existing Data';
  public attributes: any = 'Attributes Data';
  public newAttributesData: any = 'New Attributes Data';
  public inTransactionCurrency: any = 'In Transaction Currency';
  public flatCost: any = 'Flat Cost';
  public percentage: any = 'Percentage';
  public inclusiveUpperBound: any = 'Inclusive Upper Bound';
  public exclusiveLowerBound: any = 'Exclusive Lower Bound';
  public miscPricingParamType: any = 'Misc Pricing Param Type';
  public miscPricingParamTypeValue: any = 'Misc Pricing Param Type Value';
  public refundPreference: any = 'Refund Preference';
  public merchantRiskLevel: any = 'Merchant Risk Level';
  public transactionRiskLevel: any = 'Transaction Risk Level';
  public payingUserRiskLevel: any = 'Paying User Risk Level';
  public paymentDetailsRiskLevel: any = 'Payment Details Risk Level';
  public minTransactionAmount: any = 'Min Transaction Amount';

  public queryString: any = 'Query String';
  public transactionTableCode: any = 'Transaction Code';
  public nameQuery: any = 'Name Query';
  public queryStringCurrency: any = 'Query String Currency';
  public emailVerifyStatus: any = 'Email Verify Status';
  public mobileVerifyStatus: any = 'Mobile Verify Status';
  public merchantAccountState: any = 'Merchant Account State';
  public countryOfBusiness: any = 'Country Of Business';
  public countryForMobileNumber: any = 'Country For MobileNumber';
  public accountVerified: any = 'Account Verify';
  public profileStatus: any = 'Profile Status';
  public operatingCurrencies: any = 'Operating Currencies';
  public refundAmount: any = 'Refund Amount';

  public paymentInstrument: any = 'Payment Instrument';
  public paymentProvider: any = 'Payment Provider';
  public transactionCapturePreferenceType: any =
    'Transaction Capture Preference';
  public transactionCaptureFailAction: any = 'Transaction Capture Fail Action';
  public forceApplyPaymentProviderPreference: any =
    'Force Apply Payment Provider Preference';
  public forceApplyCapturePreference: any = 'Force Apply Capture Preference';
  public captureAfterHourValue: any = 'Capture After Hour Value';
  public refundAllowedWithInDays: any = 'Refund Allowed With In Days';
  public refundAllowed: any = 'Refund Allowed';
  public refundAllowedWithNegativeBalance: any =
    'Refund Allowed With Negative Balance';
  public refundAllowedForInProgressSettlementTransaction: any =
    'Refund Allowed For In Progress Settlement Transaction';
  public forceApplyPartialRefundWhenSelectingProvider: any =
    'Force Apply Partial Refund When Selecting Provider';
  public partialRefundAllowed: any = 'Partial Refund Allowed';
  public refundStrategy: any = 'Refund Strategy';
  public flatAmountApplicability: any = 'Flat Amount Applicability';

  public merchant: any = 'Merchant';
  public documentCategory: any = 'Document Category';
  public walletSettlementCycleUnits: any = 'Wallet Settlement Cycle Units';
  public settlementCycleValue: any = 'Settlement Cycle Value';

  public orgStructureModalTitle: any = 'Move Under Admin';
  public wantToReplaceTheWholeBranch: any = 'Move with all downlines';

  public type: any = 'Type';
  public emailsToNotify: any = 'Emails To Notify';
  public notifyAllAdmin: any = 'Notify All Admin';
  public notifyViaEmail: any = 'Notify Via Email';
  public notifyViaNotification: any = 'Notify Via Notification';
  public userTargetedNotification: any = 'User Targeted Notification';
  public notifyViaEmailIfNotReadWithInMinutes: any =
    'Notify Via Email If Not Read With In Minutes';
  public propagateReadStatusAcrossRelatedNotification: any =
    'Propagate Read Status Across Related Notification';
  public propagateNotificationToUpperLevelIfNotRead: any =
    'Propagate Notification To Upper Level If Not Read';
  public thresholdMinutesToReadNotification: any =
    'Threshold Minutes To Read Notification';
  public notificationBannerType: any = 'Notification Banner Type';
  public notificationBannerDestination: any = 'Notification Banner Destination';
  public thresholdDaysToTransformToBanner: any =
    'Threshold Days To Transform To Banner';

  public targetEmails: any = 'Target Emails';
  public hyperLinkToPage: any = 'Hyper Link To Page';
  public accessControlCategoryIdentifier: any =
    'Access Control Category Identifier';
  public imageHyperLink: any = 'Image  Hyper Link';
  public showAsBannerProjectWide: any = 'Show As Banner Project Wide';
  public showAsBannerOnlyOnDashboard: any =
    'Show As Banner<br>Only On Dashboard';
  public cancellable: any = 'Cancellable';
  public expiry: any = 'Expiry';

  public projectHardBlockTimeStart: any = 'Project Hard Block Time Start';
  public projectHardBlockTimeTo: any = 'Project Hard Block Time To';
  public projectSoftBlockTimeTo: any = 'Project Soft Block Time To';
  public projectSoftBlockTimeStart: any = 'Project Soft Block Time Start';
  public blockOnlyForMerchants: any = 'Block Only For Merchants';
  public softBlockAllPostApis: any = 'Soft Block All Post Apis';
  public softBlockApis: any = 'Soft Block Apis';

  public customerInfo: any = 'Customer Info';
  public customerName: any = 'Customer Name';
  public transactionCode: any = 'Transaction Code';
  public transactionType: any = 'Transaction Type';

  public amount: any = 'Amount';
  public totalAmountFromRollingReserve: any =
    'Total Amount From Rolling Reserve';
  public totalAmountFromTransactions: any = 'Total Amount From Transactions';
  public steps: any = 'Steps';
  public comment: any = 'Comment';
  public cancelReason: any = 'Cancel Reason';

  public refundableAmount: any = 'Refundable Amount';
  public effectiveAmount: any = 'Effective Amount';

  public entityName: any = 'Entity Name';
  public columnName: any = 'Column Name';

  public accountTransactionType: any = 'Account Transaction Type';
  public accountTransactionCode: any = 'Account Transaction Code';

  public chargeBackCharge: any = 'Charge Back Charge';
  public chargeBackCost: any = 'Charge Back Cost';
  public refundCharge: any = 'Refund Charge';
  public refundCost: any = 'Refund Cost';
  public transactionCharge: any = 'Transaction Charge';
  public transactionCost: any = 'Transaction Cost';

  public actualFxRate: any = 'Actual Fx Rate';
  public actualSettledAmount: any = 'Actual Settled Amount';
  public actualAmountInSettlementCurrency: any =
    'Actual Amount In Settlement Currency';
  public amountInSettlementCurrency: any = 'Amount In Settlement Currency';
  public fxRate: any = 'Fx Rate';
  public exportDocumentContext: any = 'Export Document Context';
  public column: any = 'Column';

  public paymentEnableAndDisable: any = 'Payment Enabled And Disabled';

  public state: any = 'State';
  public success: any = 'Success';
  public expired: any = 'Expired';
  public merchantCode: any = 'Merchant Code';
  public tries: any = 'Tries';
  public millsTaken: any = 'Mills Taken';
}

import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LoginRequest } from 'src/models/reqres/admin-login-request';
import { Observable } from 'rxjs/internal/Observable';
import { TokenAndRefreshToken } from 'src/models/tokenandrefreshtoken.model';
import { ProfileResponseModel } from '../../../models/profile.model';
import { Apis } from 'src/core/apis';
import { Subject } from 'rxjs';

const API_LOGIN_URL = Apis.adminLogin;
const API_USER_ME_URL = Apis.me;
const API_ALL_ADMIN_URL = Apis.getAllAdmin;
const API_CHECK_OTP_REQUIRED_FOR_IMPERSONATION_URL =
  Apis.checkIfOTPRequiredForImpersonation;
const API_OTP_FOR_IMPERSONATION_URL = Apis.otpForImpersonation;

@Injectable({
  providedIn: 'root',
})
export class AdminLoginService implements OnDestroy {
  callbackResponseForUser = new Subject();

  constructor(private http: HttpClient) {}

  login(data: LoginRequest): Observable<TokenAndRefreshToken> {
    return this.http.post<TokenAndRefreshToken>(API_LOGIN_URL, data);
  }
  user(): Observable<ProfileResponseModel> {
    return this.http.get<any>(API_USER_ME_URL);
  }
  allAdmin(): Observable<any> {
    return this.http.get<any>(API_ALL_ADMIN_URL);
  }

  checkIfOTPRequiredForImpersonation(): Observable<any> {
    return this.http.get<any>(API_CHECK_OTP_REQUIRED_FOR_IMPERSONATION_URL);
  }
  getImpersonationOTP(): Observable<any> {
    return this.http.get<any>(API_OTP_FOR_IMPERSONATION_URL);
  }

  updateUser(val: any) {
    this.callbackResponseForUser.next(val);
  }

  ngOnDestroy() {}
}

import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { Apis } from '../../core/apis';
import { AccessControlCategory } from '../../models/access-control.model';
import { GenericListResponse } from '../../models/generic-list-response';
import { Subject } from 'rxjs';
const API_ACCESS_CONTROL_URL = Apis.accessControl;
const API_BY_CATEGORY_URL = Apis.byCategory;
const API_ACCESS_CONTROL_UPDATE_URL = Apis.accessControlUpdate;
const API_ALL_ACCESS_CONTROL_CATEGORY_URL = Apis.allAccessControlCategory;

@Injectable({
  providedIn: 'root',
})
export class SideBarService implements OnDestroy {
  constructor(private http: HttpClient) {}
  callbackResponse = new Subject();
  getbyCategory(): Observable<GenericListResponse<AccessControlCategory>> {
    return this.http.get<GenericListResponse<AccessControlCategory>>(API_BY_CATEGORY_URL);
  }

  getAccessControl(): Observable<any> {
    return this.http.get<any>(API_ACCESS_CONTROL_URL);
  }

  updateAccessControlCategory(id: any, order: any): Observable<any> {
    return this.http.post<any>(API_ACCESS_CONTROL_UPDATE_URL, {
      categoryId: id,
      order: order,
    });
  }
  getAllAccessControlCategory():Observable<GenericListResponse<AccessControlCategory>>{
    return this.http.get<GenericListResponse<AccessControlCategory>>(API_ALL_ACCESS_CONTROL_CATEGORY_URL);
  }
  updateLocalStorage(val: any) {
    this.callbackResponse.next(val);
  }
  ngOnDestroy() {}
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { KeywordsAndConstants } from '../../../core/keywords-and-constants';

@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss'],
})
export class VerticalComponent implements OnInit, AfterViewInit {
  isCondensed: any = false;
  // sidebartype: string;

  constructor(private router: Router, private constants: KeywordsAndConstants) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        document.body.classList.remove('sidebar-enable');
      }
    });
  }

  ngOnInit() {
    document.body.setAttribute('data-layout', 'vertical');
  }
  gotoMessage() {
    let loginType = localStorage.getItem(this.constants.isLoginType);
    if (loginType == 'ADMIN') {
      this.router.navigate(['/admin/message-trail-head']);
    } else if (loginType == 'MERCHANT') {
      this.router.navigate(['/merchant/message-trail-head']);
    }
  }
  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      ua
    );
  }

  ngAfterViewInit() {}

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    this.isCondensed = !this.isCondensed;
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');

    if (window.screen.width <= 992) {
      document.body.classList.remove('vertical-collpsed');
    }
  }
}
